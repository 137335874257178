import React, { useRef, useState } from 'react';
import { ValueSelectedParameter } from '../draw/SankeyDrawFunction';
import { OSTooltip } from './SankeyUtils';
import { Box, Input, InputGroup, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select } from '@chakra-ui/react';
/*************************************************************************************************/
export const MenuConfigurationLinksData = ({ applicationData, applicationState, applicationContext, additional_data_element, ComponentUpdater, node_function, link_function }) => {
    const { t } = applicationContext;
    const [forceUpdate, setForceUpdate] = useState(false);
    const { data } = applicationData;
    const { multi_selected_links, displayedInputLinkValueSetterRef, displayedInputLinkValueRef, displayedInputLinkDataTagSetterRef } = applicationState;
    const [displayed_input_link_value, set_displayed_input_link_value] = useState('');
    displayedInputLinkValueSetterRef.current.push(set_displayed_input_link_value);
    displayedInputLinkValueRef.current = displayed_input_link_value;
    const newEntries = new Map(Object.entries(data.dataTags).map(([dataTagKey, dataTag]) => {
        return (Object.keys(dataTag.tags).length > 0) ? [
            dataTagKey,
            Object.entries(dataTag.tags).filter(tag => tag[1].selected).length > 0 ? Object.entries(dataTag.tags).filter(tag => tag[1].selected)[0][0] : Object.keys(dataTag.tags)[0]
        ] : ['n', 'n'];
    }));
    const dataTagsSelected = Object.fromEntries(newEntries);
    const [tags_selected, set_tags_selected] = useState(dataTagsSelected);
    displayedInputLinkDataTagSetterRef.current.push(set_tags_selected);
    if (Object.keys(tags_selected).length !== Object.keys(dataTagsSelected).length) {
        set_tags_selected(dataTagsSelected);
    }
    const content = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, // Définition des valeurs selon les paramètre dataTags
        Object.entries(data.dataTags).map(([dataTagKey, dataTag]) => {
            if (Object.keys(dataTag.tags).length != 0) {
                return (React.createElement(React.Fragment, null,
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_3' }, dataTag.group_name),
                    React.createElement(Select, { name: dataTagKey, variant: 'menuconfigpanel_option_select', value: tags_selected[dataTagKey], onChange: (evt) => {
                            //Modifie les paramètres selectionnés
                            const { name, value } = evt.target;
                            let tmp = {};
                            // set_tags_selected( prevState => {
                            tmp = (Object.assign(Object.assign({}, tags_selected), { [name]: value }));
                            set_tags_selected(tmp);
                            //   return ({...prevState,[name]: value}) 
                            // } )
                            displayedInputLinkValueSetterRef.current.forEach(setter => setter(ValueSelectedParameter(applicationData, multi_selected_links, tmp).value));
                        } }, Object.entries(dataTag.tags).map(([tag_key, tag]) => {
                        return (React.createElement("option", { key: tag.name, value: tag_key }, tag.name));
                    }))));
            }
        }),
        React.createElement(OSTooltip, { label: t('Flux.data.tooltips.vpp') },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Flux.data.vpp')),
                React.createElement(ConfigLinkDataNumberInput, { applicationData: applicationData, multi_selected_links: multi_selected_links, tags_selected: tags_selected, node_function: node_function, link_function: link_function, ComponentUpdater: ComponentUpdater }))),
        React.createElement(OSTooltip, { label: t('Flux.data.tooltips.affichage') },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Flux.data.affichage')),
                React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                    React.createElement(Input, { variant: 'menuconfigpanel_option_input', value: ValueSelectedParameter(applicationData, multi_selected_links, tags_selected).display_value, onChange: evt => {
                            let val = Object(multi_selected_links.current[0].value);
                            multi_selected_links.current.map(d => {
                                val = d.value;
                                Object.values(tags_selected).forEach(tag => {
                                    if (val[tag] === undefined) {
                                        val[tag] = {};
                                    }
                                    val = val[tag];
                                });
                                val.display_value = evt.target.value;
                            });
                            setForceUpdate(!forceUpdate);
                            link_function.RedrawLinks(multi_selected_links.current);
                        } })))),
        additional_data_element);
    return content;
};
/**
 * Component developped for number input of the layout config menu
 *
 * @param {SankeyData} data
 * @param {keyof SankeyData} var_of_data keyof of the variable we want to reference in the inputn the variable in SankeyData need to be a number
 * @param {number} minimum_value (optional, if not specified it mean the value can be undefined )
 * @param {boolean} stepper (default:false) add stepper to the input to increase or decrease the value
 * @param {boolean} hasUnit (default:false) add an addon after the input
 * @param {string} unitText (default:'') text of the addon
 * @param {function} function_onBlur function called when we leave the input, it is generally used to update the draw area
 *
 * @return {JSX.Elmement}
 */
export const ConfigLinkDataNumberInput = ({ applicationData, multi_selected_links, tags_selected, node_function, link_function, ComponentUpdater, }) => {
    const { data } = applicationData;
    const ref_input = useRef(null);
    const variantOfInput = 'menuconfigpanel_option_numberinput';
    const isModifying = useRef();
    // Initialise hook with first link selected value
    //const [displayed_value,setDisplayedValue]=useState(()=>{
    const val_of_key = ValueSelectedParameter(applicationData, multi_selected_links, tags_selected).value;
    // Initialise hook with first link selected value
    const [displayed_value, setDisplayedValue] = useState(val_of_key);
    // Add stepper addon if specified
    const stepperBtn = React.createElement(NumberInputStepper, null,
        React.createElement(NumberIncrementStepper, null),
        React.createElement(NumberDecrementStepper, null));
    const f_onBlur = () => {
        node_function.RedrawNodes(Object.values(applicationData.display_nodes));
        link_function.RedrawLinks(Object.values(applicationData.display_links));
        ComponentUpdater.updateComponenSaveInCache.current(false);
    };
    return React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
        React.createElement(NumberInput, { allowMouseWheel: true, variant: variantOfInput, step: 1, value: isModifying.current ? displayed_value : val_of_key, onChange: (_) => {
                // Launch/reset timeout before the input auto blur (and update the value in data)
                if (isModifying.current) {
                    clearTimeout(isModifying.current);
                }
                isModifying.current = setTimeout(() => {
                    var _a;
                    f_onBlur();
                    (_a = ref_input.current) === null || _a === void 0 ? void 0 : _a.blur();
                }, 2000);
                // Update displayed value
                setDisplayedValue(_);
            }, onBlur: () => {
                clearTimeout(isModifying.current);
                isModifying.current = undefined;
                // const formatedValue=displayed_value.replace(',','.')
                if (displayed_value !== '' && !isNaN(+displayed_value)) {
                    let val = Object(multi_selected_links.current[0].value);
                    const node_to_update = [];
                    multi_selected_links.current.map(d => {
                        node_to_update.push(data.nodes[d.idSource]);
                        node_to_update.push(data.nodes[d.idTarget]);
                        val = d.value;
                        Object.values(tags_selected).forEach(tag => {
                            if (val[tag] === undefined) {
                                val[tag] = {};
                            }
                            val = val[tag];
                        });
                        val.value = +displayed_value;
                    });
                    if (Object.values(data.links).length == 1) {
                        data.user_scale = +displayed_value;
                    }
                }
                else if (displayed_value == '') {
                    let val = Object(multi_selected_links.current[0].value);
                    const node_to_update = [];
                    multi_selected_links.current.map(d => {
                        node_to_update.push(data.nodes[d.idSource]);
                        node_to_update.push(data.nodes[d.idTarget]);
                        val = d.value;
                        Object.values(tags_selected).forEach(tag => {
                            if (val[tag] === undefined) {
                                val[tag] = {};
                            }
                            val = val[tag];
                        });
                        val.value = '';
                    });
                }
                f_onBlur();
            } },
            React.createElement(NumberInputField, { ref: ref_input }),
            stepperBtn));
};
