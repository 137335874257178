import React, { useEffect, useState, } from 'react';
import * as d3 from 'd3';
import { AdjustSankeyZone, AssignNodeLocalAttribute, DefaultLink, DefaultNode, DefaultSankeyData, GetRandomInt, SetNodeStyleToTypeNode, list_palette_color } from '../configmenus/SankeyUtils';
import FileSaver from 'file-saver';
import { complete_sankey_data } from '../configmenus/SankeyConvert';
import { ArrangeTrade, ComputeAutoSankey, RecomputeTrade, SplitTrade, compute_default_input_outputLinksId, reorganize_all_input_outputLinksId } from '../draw/SankeyDrawLayout';
import { LinkVisibleOnSvg, NodeVisibleOnsSvg } from '../draw/SankeyDrawFunction';
import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner } from '@chakra-ui/react';
const SankeyLoad = ({ applicationContext, applicationDraw, applicationData, successAction, processFunctions, dict_hook_ref_setter_show_dialog_components, convert_data }) => {
    const { t, url_prefix } = applicationContext;
    const { ref_processing, ref_setter_processing, failure, ref_result, not_started, RetrieveExcelResults } = processFunctions;
    const [value, setValue] = useState([1, 2]);
    const [show_load_dialog, set_show_load_dialog] = useState(false);
    dict_hook_ref_setter_show_dialog_components.ref_setter_show_load.current = set_show_load_dialog;
    const [result, set_result] = useState('');
    ref_result.current = set_result;
    const [processing, set_processing] = useState(false);
    ref_setter_processing.current = set_processing;
    const [is_computing, set_is_computing] = useState(false);
    const reset = () => {
        set_processing(false);
        ref_processing.current = false;
        failure.current = false;
        set_is_computing(false);
        not_started.current = true;
    };
    const handleChange = (evt) => {
        if (value.includes(+evt.target.value)) {
            value.splice(value.indexOf(evt.target.value));
        }
        else {
            value.push(+evt.target.value);
        }
        setValue([...value]);
    };
    const infos = result !== undefined ? result.split('\n') : [];
    const success_status = t('Menu.loaded_file');
    const failure_status = t('Menu.failure_file');
    const spinner = (processing || is_computing) ? React.createElement(Spinner, { thickness: '2px', color: 'openSankey.200' }) : React.createElement(React.Fragment, null);
    if (!not_started.current && !processing) {
        const path = window.location.origin;
        const url = path + applicationContext.url_prefix + 'loads_retrieves_result';
        const form_data = new FormData();
        const fetchData = {
            method: 'POST',
            body: form_data
        };
        fetch(url, fetchData).then(response => {
            response.text().then(text => {
                RetrieveExcelResults(applicationData, text, applicationDraw.updateLayout, applicationDraw.GetSankeyMinWidthAndHeight, convert_data, applicationData.get_default_data);
            }).then(() => {
                set_is_computing(false);
            });
        });
        set_processing(false);
        ref_processing.current = false;
        failure.current = false;
        not_started.current = true;
    }
    return (React.createElement(Modal, { isOpen: show_load_dialog, onClose: () => set_show_load_dialog(false) },
        React.createElement(ModalOverlay, null),
        React.createElement(ModalContent, { display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: 'inherit' },
            React.createElement(ModalHeader, null,
                "Chargement du fichier ",
                spinner),
            React.createElement(ModalCloseButton, null),
            React.createElement(ModalBody, null,
                React.createElement(Box, { layerStyle: 'menucontext_layout', width: '60rem' },
                    React.createElement(Box, null, processing ? (React.createElement(Button, { variant: "menuconfigpanel_option_button_tertiary" },
                        React.createElement("span", { className: "glyphicon glyphicon-refresh glyphicon-refresh-animate" }),
                        t('Menu.load_file'))) : (failure.current ? (React.createElement(Button, { variant: "menuconfigpanel_del_button", onClick: reset }, failure_status)) : React.createElement(React.Fragment, null, is_computing ? (React.createElement(Button, { variant: 'menuconfigpanel_option_button_secondary' }, t('Menu.compute_file'))) : (React.createElement(Button, { variant: "menuconfigpanel_option_button", onClick: () => {
                            successAction();
                            set_show_load_dialog(false);
                        } }, success_status))))),
                    React.createElement(Box, { layerStyle: 'options_3cols' },
                        React.createElement(Button, { onClick: evt => handleChange(evt), value: 1, variant: value.includes(1) ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button' }, "Infos"),
                        React.createElement(Button, { onClick: evt => handleChange(evt), value: 2, variant: value.includes(2) ? 'menuconfigpanel_option_button_secondary_activated' : 'menuconfigpanel_option_button_secondary' }, "Erreurs"),
                        React.createElement(Button, { onClick: evt => handleChange(evt), value: 3, variant: value.includes(3) ? 'menuconfigpanel_option_button_tertiary_activated' : 'menuconfigpanel_option_button_tertiary' }, "Debug")),
                    processing ? (React.createElement(Counter, { url_prefix: url_prefix, finishReconciliation: () => {
                            set_processing(false);
                            ref_processing.current = false;
                            set_is_computing(true);
                            failure.current = false;
                        }, value: value, result: result, set_result: set_result })) : (React.createElement(React.Fragment, null,
                        React.createElement(Box, null, infos.map((info) => (value.includes(2) && info.includes('ERROR') ?
                            (React.createElement("div", { style: { color: 'red' } }, info.replace('ERROR', '')))
                            : value.includes(1) && info.includes('INFO') && !info.includes('POST') ?
                                (React.createElement("div", { style: { color: 'blue' } }, info.replace('INFO', '')))
                                : value.includes(3) && (info.includes('DEBUG')) ?
                                    (React.createElement("div", { style: { color: 'orange' } }, info.replace('DEBUG', ''))) : (null)))))))))));
};
/**
 * Description placeholder
 *
 * @param {{url_prefix:string,finishReconciliation:(x:boolean)=>void,value:number[],result:string,setResult:(x:string)=>void}} {url_prefix,finishReconciliation,value,result,setResult}
 * @returns {void; value: {}; result: string; setResult: (x: string) => void; }) => any}
 */
export const Counter = ({ url_prefix, finishReconciliation, value, result, set_result }) => {
    useEffect(() => {
        const interval = setInterval(() => {
            const root = window.location.origin;
            const url = root + url_prefix + 'load_process';
            const fetchData = {
                method: 'POST',
                body: ''
            };
            fetch(url, fetchData).then(function (response) {
                if (response.ok) {
                    response.json().then(function (data) {
                        set_result(data.output);
                    });
                }
            });
        }, 5000);
        return () => clearInterval(interval);
    });
    const infos = result.split('\n');
    if (infos.length > 2) {
        if (result.includes('FINISHED')) {
            finishReconciliation(false);
        }
        else if (result.includes('FAILED')) {
            finishReconciliation(true);
        }
    }
    return (React.createElement(Box, null, infos.map(info => (value.includes(2) && info.includes('ERROR') ?
        (React.createElement("div", { style: { color: 'red' } }, info.replace('ERROR', '')))
        : value.includes(1) && info.includes('INFO') && !info.includes('POST') ?
            (React.createElement("div", { style: { color: 'blue' } }, info.replace('INFO', '')))
            : value.includes(3) && (info.includes('DEBUG')) ?
                (React.createElement("div", { style: { color: 'orange' } }, info.replace('DEBUG', ''))) : (null)))));
};
export default SankeyLoad;
export const RetrieveExcelResults = (applicationData, text, updateLayout, GetSankeyMinWidthAndHeight, convert_data, defaultData) => {
    var _a;
    const { set_data } = applicationData;
    const default_data = defaultData();
    const server_data = JSON.parse(text);
    // let default_nstyle = default_data.style_node['default']
    // let default_lstyle = default_data.style_link['default']
    // server_data.h_space = default_data.h_space
    // server_data.v_space = default_data.v_space
    if (default_data.layout) {
        server_data.layout = default_data.layout;
    } /*else {
      default_nstyle = JSON.parse(JSON.stringify(default_data.style_node['default']))
      default_lstyle = JSON.parse(JSON.stringify(default_data.style_link['default']))
    }*/
    Object.values(default_data.style_node).forEach(n => n.node_height = 3);
    Object.values(default_data.style_link).forEach(l => l.gradient = true);
    const new_data = Object.assign(default_data, server_data);
    applicationData.data = new_data;
    //Object.values(new_data.style_node).forEach(n=>n.node_height = 3)
    ProcessExample(applicationData, updateLayout, convert_data, DefaultSankeyData);
    // new_data.style_node['default'] = default_nstyle
    // new_data.style_link['default'] = default_lstyle
    delete new_data.layout;
    if (Object.values(new_data.nodeTags).filter(tagg => tagg.show_legend).length > 0) {
        new_data.colorMap = Object.entries(new_data.nodeTags).filter(tagg => tagg[1].show_legend)[0][0];
        Object.values(new_data.nodes).forEach(el => {
            el.colorParameter = 'groupTag';
            el.colorTag = new_data.colorMap;
        });
    }
    if (Object.keys(new_data.nodeTags).filter(t => new_data.nodeTags[t].show_legend).length == 0 &&
        Object.keys(new_data.fluxTags).filter(tag => tag === 'flux_type').length == 0 &&
        Object.values(new_data.nodes).filter(n => n.local && n.local.color).length == 0 &&
        Object.values(new_data.links).filter(l => l.local && l.local.color).length == 0) {
        const color_selected = list_palette_color[GetRandomInt(list_palette_color.length)];
        const n_keys = Object.keys(new_data.nodes);
        const size_color = n_keys.length;
        for (const i in d3.range(size_color)) {
            AssignNodeLocalAttribute(new_data.nodes[n_keys[i]], 'color', (_a = d3.color(color_selected(+i / size_color))) === null || _a === void 0 ? void 0 : _a.formatHex());
        }
    }
    set_data(Object.assign({}, new_data));
    setTimeout(() => {
        AdjustSankeyZone(applicationData, GetSankeyMinWidthAndHeight);
    }, 100);
};
export const ClickSaveDiagram = (applicationData, data, dict_hook_ref_setter_show_dialog_components, options) => {
    // const data_to_save = { ...applicationData.data }
    // const str_data = JSON.stringify(data_to_save)
    // Crée une copie pour d'abord enregitrer avec les changements
    // (ClickSaveDiagram utilise data donc on doit faire un set_data avant mais aussi garder la version sans les changements)
    const cpy = JSON.parse(JSON.stringify(data));
    if (!options.mode_save) {
        Object.values(cpy.links).forEach(d => {
            d.value = {};
        });
    }
    if (options.mode_visible_element) {
        // Si l'on enregistre que les element visible alors on cherche les élements visible dasns le svg
        const link_present = LinkVisibleOnSvg();
        const node_visible = NodeVisibleOnsSvg();
        cpy.links = Object.fromEntries(Object.entries(cpy.links).filter(l => link_present.includes(l[0])).map(l => l));
        const key_level_tags = Object.keys(cpy.levelTags);
        cpy.nodes = Object.fromEntries(Object.entries(cpy.nodes).filter(n => node_visible.includes(n[0])).map(n => {
            key_level_tags.forEach(klt => {
                delete n[1].tags[klt];
            });
            n[1].dimensions = {};
            n[1].inputLinksId = n[1].inputLinksId.filter(lid => link_present.includes(lid));
            n[1].outputLinksId = n[1].outputLinksId.filter(lid => link_present.includes(lid));
            return n;
        }));
        cpy.levelTags = {};
        cpy.linkZIndex = link_present;
        cpy.view = [];
    }
    //RecomputeTrade(cpy)
    const str_data = JSON.stringify(cpy);
    const blob = new Blob([str_data], { type: 'text/plain;charset=utf-8' });
    const dataAsSuite = cpy;
    let name = 'Diagramme de Sankey';
    if (dataAsSuite.view && dataAsSuite.view.length > 0 && !dataAsSuite.is_catalog) {
        name = 'Diagramme de Sankey avec vues';
    }
    else if (dataAsSuite.is_catalog === true) {
        name = 'Catalogue de vues de diagrammes de Sankey';
    }
    FileSaver.saveAs(blob, name + '.json');
};
export const ProcessExample = (applicationData, updateLayout, convert_data, DefaultSankeyData) => {
    const { data } = applicationData;
    complete_sankey_data(data, DefaultSankeyData, DefaultNode, DefaultLink);
    convert_data(data, DefaultSankeyData);
    if (data.layout === undefined) {
        // Compute node position of all node according to their level tags
        const lvl_tag_keys = Object.keys(data.levelTags);
        // If data only have level Tag 'Primaire' then compute node position at each levle
        if ((lvl_tag_keys.length == 1) && lvl_tag_keys[0] === 'Primaire') {
            const prim = lvl_tag_keys[0];
            Object.values(data.levelTags[prim].tags).reverse().forEach(tag_prim => {
                // Deselect all Primaire tags
                Object.values(data.levelTags[prim].tags).forEach(t => t.selected = false);
                // Select current tag to compute position
                tag_prim.selected = true;
                ComputeAutoSankey(applicationData, true);
            });
        }
        else if ((lvl_tag_keys.length > 1)) {
            // If data have multiple level Tag
            // then compute node position at each level of each level tag group
            // except 'Primaire'
            lvl_tag_keys.filter(kt => kt !== 'Primaire').forEach(kt => {
                Object.values(data.levelTags[kt].tags).reverse().forEach(tag_prim => {
                    // Deselect all tags of the current grp tag
                    Object.values(data.levelTags[kt].tags).forEach(t => t.selected = false);
                    // Select current tag to compute position
                    tag_prim.selected = true;
                    ComputeAutoSankey(applicationData, true);
                });
            });
        }
        else {
            ComputeAutoSankey(applicationData, true);
        }
        Object.values(applicationData.data.nodes).forEach(n => {
            // Place labels accordingly
            // If node is lone, source, sink or in the middle
            if ((n.inputLinksId.length === 0) &&
                (n.outputLinksId.length === 0)) {
                // Node is lone node
                AssignNodeLocalAttribute(n, 'label_horiz', 'middle');
                AssignNodeLocalAttribute(n, 'label_vert', 'middle');
                AssignNodeLocalAttribute(n, 'label_background', true);
            }
            else if (n.inputLinksId.length === 0) {
                // Node is a source : no input link
                AssignNodeLocalAttribute(n, 'label_horiz', 'left');
                AssignNodeLocalAttribute(n, 'label_vert', 'middle');
            }
            else if (n.outputLinksId.length === 0) {
                // Node is a sink : no output link
                AssignNodeLocalAttribute(n, 'label_horiz', 'right');
                AssignNodeLocalAttribute(n, 'label_vert', 'middle');
            }
            else {
                // Node is in the middle of the sankey
                AssignNodeLocalAttribute(n, 'label_horiz', 'left');
                AssignNodeLocalAttribute(n, 'label_vert', 'middle');
                AssignNodeLocalAttribute(n, 'label_background', true);
            }
        });
        SplitTrade(applicationData.data);
        ArrangeTrade(applicationData, true);
        compute_default_input_outputLinksId(data.nodes, data.links);
        reorganize_all_input_outputLinksId(data, data.nodes, data.links);
        // Set sector/product style to node only when it come from an excel file and without a layout
        SetNodeStyleToTypeNode(data);
    }
    else {
        convert_data(data.layout, DefaultSankeyData);
        complete_sankey_data(data.layout, DefaultSankeyData, DefaultNode, DefaultLink);
        compute_default_input_outputLinksId(data.nodes, data.links);
        const data_layout = JSON.parse(JSON.stringify(data.layout));
        delete data.layout;
        updateLayout(data, data_layout, ['posNode', 'posFlux', 'attrNode', 'attrFlux', 'attrGeneral', 'freeLabels', 'Views', 'tagNode', 'tagFlux', 'tagLevel', 'icon_catalog'], true);
        SplitTrade(applicationData.data);
        ArrangeTrade(applicationData, true);
    }
    d3.select('.loading_auto_compute').remove();
    return data;
};
/**
 * Download examples from server
 *
 * @param {string} file_name
 * @param {string} the_url_prefix
 * @param {string} filetype
 */
export const DownloadExamples = (file_name, the_url_prefix, filetype) => {
    const root = window.location.origin;
    const url = root + '/opensankey/sankey/download_examples';
    const fetchData = {
        method: 'POST',
        body: file_name
    };
    const showFile = (blob) => {
        const newBlob = new Blob([blob], { type: filetype });
        FileSaver.saveAs(newBlob, file_name);
    };
    fetch(url, fetchData).then(response => {
        if (response.ok) {
            response.blob().then(showFile);
        }
    });
};
export const UploadExcelImpl = (set_show_excel_dialog, input_file, the_url_prefix) => {
    const root = window.location.origin;
    const url = root + the_url_prefix + 'sankey/upload_excel';
    const form_data = new FormData();
    form_data.append('file', input_file);
    const fetchData = {
        method: 'POST',
        body: form_data
    };
    fetch(url, fetchData);
    set_show_excel_dialog(false);
};
/**
 *
 * @param {string} file_name
 * @param {string} the_url_prefix
 * @param {SankeyData} data
 * @param {(data: SankeyData) => void} set_data
 * @returns {void) => void}
 */
export const UploadExemple = (file_name, the_url_prefix, data, set_data, Reinitialization, convert_data, DefaultSankeyData) => {
    let root = window.location.origin;
    if (root.includes('dashboard')) {
        root = root.replace('dashboard', '');
    }
    const url = root + the_url_prefix + '/sankey/upload_examples';
    const fetchData = {
        method: 'POST',
        body: file_name
    };
    fetch(url, fetchData).then((response) => {
        response.text().then((text) => {
            const server_data = JSON.parse(text);
            const error = server_data['error'];
            if (error && error.length != 0) {
                alert(error);
                return;
            }
            if (!file_name.includes('.xlsx')) {
                Reinitialization();
                complete_sankey_data(server_data, DefaultSankeyData, DefaultNode, DefaultLink);
                convert_data(server_data, DefaultSankeyData);
                set_data(Object.assign({}, server_data));
            }
        });
    });
};
export const ClickSaveExcel = (url_prefix, data, file_name = 'sankey') => {
    let root = window.location.origin;
    if (root.includes('dashboard')) {
        root = root.replace('dashboard', '');
    }
    let url = root + url_prefix + 'sankey/save_excel';
    const cpy = JSON.parse(JSON.stringify(data));
    RecomputeTrade(cpy);
    const fetchData = {
        method: 'POST',
        body: JSON.stringify(cpy)
    };
    const showFile = (blob) => {
        const newBlob = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(newBlob, file_name + '.xlsx');
    };
    const cleanFile = () => {
        const fetchData = {
            method: 'POST'
        };
        url = root + url_prefix + 'sankey/clean_excel';
        fetch(url, fetchData);
    };
    fetch(url, fetchData).then(r => r.blob())
        .then(showFile).then(cleanFile);
};
