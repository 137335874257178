// Import From sankeyDev
import * as HideLoneNodeFunction from 'sankeydev/dist/src/HideLoneNodeFunction'
import * as PercentValue from 'sankeydev/dist/src/PercentValue'
import * as LicenceBlockers from 'sankeydev/dist/src/LicenceBlockers'
import * as SankeyDevUnitarySankey from 'sankeydev/dist/src/UnitarySankey/UnitarySankeyDialogs'
import * as PublishModale from 'sankeydev/dist/src/PublishModale'
import * as MenuExampleDemo from 'sankeydev/dist/src/MenuExampleDemo'
import * as DevMenuEnregistrerExcelSimple from 'sankeydev/dist/src/MenuEnregistrerExcelSimple'
import * as NodeNotToScale from 'sankeydev/dist/src/NodeNotToScale'
import * as DevDialogs from 'sankeydev/dist/src/SankeyDevMenuDialog'
//import * as DevConvert from 'sankeydev/dist/src/SankeyDevConvert'
import { dev_convert_dataFType } from 'sankeydev/types/SankeyDevConvertTypes'
import * as OSDev from 'sankeydev/dist/src/OSDevModule'

import { MenuConfigHideLoneNodeFType, hiddeLoneNodesFType, searchAndRestoreLoneNodesFType} from 'sankeydev/types/HideLoneNodeFunctionTypes'
import { SankeyDevMenuDataPercentFType, devGetLinkValueFType } from 'sankeydev/types/PercentValueTypes'
import { blockers_suite_sankeyFType} from 'sankeydev/types/LicenceBlockersTypes'
import { SankeyDevBannerUnitsFType, context_node_view_node_unitaryFType} from 'sankeydev/types/SankeyDevUnitarySankeyTypes'
import { PublishModalTypes, SankeyDevMenuPublishItemFType } from 'sankeydev/types/PublishModaleTypes'
import { MenuEnregistrerExcelSimpleFType } from 'sankeydev/types/MenuEnregistrerExcelSimpleTypes'
import { SankeyDevDrawNodesNotToScaleFType, SankeyDevNodesAttributesFType } from 'sankeydev/types/NodeNotToScaleTypes'
import { itemDemoFType } from 'sankeydev/types/MenuExampleDemoTypes'
import { FunctionComponent } from 'react'

import { DrawAllType, InstallEventsOnSVGType, initializeAdditionalMenusType, initializeApplicationDataType, initializeApplicationDrawType, initializeProcessFunctionsType, initializeReinitializationType, module_dialogsType } from 'open-sankey/src/types/Types'
import { OSDevInitializeApplicationContextType, OSDevInitializeComponentUpdaterType, OSDevInitializeContextMenuType,OSDevInitializeElementSelectedType,OSDevInitializeLinkFunctionTypes,OSDevInitializeShowDialogType,OSDevInitializeUiElementsRefType,OSDevnItializeNodeFunctionsType } from 'sankeydev/types/Types'
import { ClickSaveDiagramFuncType } from 'open-sankey/src/dialogs/types/SankeyPersistenceTypes'

export const hiddeLoneNodes =
    HideLoneNodeFunction.hiddeLoneNodes as hiddeLoneNodesFType
export const searchAndRestoreLoneNodes =
    HideLoneNodeFunction.searchAndRestoreLoneNodes as searchAndRestoreLoneNodesFType
export const MenuConfigHideLoneNode =
    HideLoneNodeFunction.MenuConfigHideLoneNode as FunctionComponent<MenuConfigHideLoneNodeFType>
export const devGetLinkValue =
    PercentValue.devGetLinkValue as devGetLinkValueFType
export const SankeyDevMenuDataPercent =
    PercentValue.SankeyDevMenuDataPercent as FunctionComponent<SankeyDevMenuDataPercentFType>
export const blockers_suite_sankey =
    LicenceBlockers.blockers_suite_sankey as blockers_suite_sankeyFType
export const itemDemo =
    MenuExampleDemo.itemDemo as itemDemoFType
export const MenuEnregistrerExcelSimple =
    DevMenuEnregistrerExcelSimple.MenuEnregistrerExcelSimple as MenuEnregistrerExcelSimpleFType
export const SankeyDevNodesAttributes =
    NodeNotToScale.SankeyDevNodesAttributes as FunctionComponent<SankeyDevNodesAttributesFType>
export const SankeyDevBannerUnit =
    SankeyDevUnitarySankey.SankeyDevBannerUnit as FunctionComponent<SankeyDevBannerUnitsFType>
export const context_node_view_node_unitary =
    SankeyDevUnitarySankey.context_node_view_node_unitary as context_node_view_node_unitaryFType
// export const SankeyDev_link_text =
//     SankeyDevUnitarySankey. as SankeyDev_link_textFType
// export const SankeyDev_legend_elements =
//     SankeyDevUnitarySankey.SankeyDev_legend_elements as SankeyDev_legend_elementsFType
export const SankeyDevMenuPublishItem =
    PublishModale.SankeyDevMenuPublishItem as FunctionComponent<SankeyDevMenuPublishItemFType>
export const PublishModal :FunctionComponent<PublishModalTypes> =
    PublishModale.PublishModal

export const SankeyDevDrawNodesNotToScale:SankeyDevDrawNodesNotToScaleFType=NodeNotToScale.SankeyDevDrawNodesNotToScale


export const OSDevInitializeApplicationContext = OSDev.OSDevInitializeApplicationContext as OSDevInitializeApplicationContextType
export const OSDevinitializeApplicationData = OSDev.OSDevInitializeApplicationData as unknown as initializeApplicationDataType
export const OSDevInitializeElementSelected = OSDev.OSDevInitializeElementSelected as OSDevInitializeElementSelectedType
export const OSDevInitializeApplicationDraw = OSDev.OSDevInitializeApplicationDraw as  unknown as initializeApplicationDrawType
export const OSDevInitializeShowDialog = OSDev.OSDevInitializeShowDialog as OSDevInitializeShowDialogType
export const OSDevInitializeComponentUpdater = OSDev.OSDevInitializeComponentUpdater as OSDevInitializeComponentUpdaterType
export const OSDevInitializeReinitialization  = OSDev.OSDevInitializeReinitialization as initializeReinitializationType
export const OSDevInitializeProcessFunctions = OSDev.OSDevInitializeProcessFunctions as initializeProcessFunctionsType
export const OSDevInitializeContextMenu = OSDev.OSDevInitializeContextMenu as OSDevInitializeContextMenuType
export const OSDevInitializeUIElementsRef = OSDev.OSDevInitializeUIElementsRef as OSDevInitializeUiElementsRefType
export const OSDevInitializeLinkFunctions  = OSDev.OSDevInitializeLinkFunctions as OSDevInitializeLinkFunctionTypes
export const OSDevInitializeNodeFunctions = OSDev.OSDevInitializeNodeFunctions as OSDevnItializeNodeFunctionsType
export const OSDevInitializeAdditionalMenus = OSDev.OSDevInitializeAdditionalMenus as initializeAdditionalMenusType
export const OSDevModuleDialogs = OSDev.OSDevModuleDialogs as module_dialogsType
export const OSDevDrawAll = OSDev.OSDevDrawAll as DrawAllType
export const OSDevInstallEventsOnSVG = OSDev.OSDevInstallEventsOnSVG as InstallEventsOnSVGType

export const SankeyDevClickSaveDiagram = DevDialogs.SankeyDevClickSaveDiagram as ClickSaveDiagramFuncType

//export const dev_convert_data = DevConvert.dev_convert_data as dev_convert_dataFType