import * as sankeyUtils from 'open-sankey/dist/configmenus/SankeyUtils';
import * as sankeyLoad from 'open-sankey/dist/dialogs/SankeyPersistence';
import * as SankeyDrawFunc from 'open-sankey/dist/draw/SankeyDrawFunction';
import * as SankeyDrawEventFunc from 'open-sankey/dist/draw/SankeyDrawEventFunction';
import * as sankeyConvert from 'open-sankey/dist/configmenus/SankeyConvert';
import * as SankeyTooltip from 'open-sankey/dist/draw/SankeyTooltip';
import * as SankeyDrawNodes from 'open-sankey/dist/draw/SankeyDrawNodes';
import * as SankeyDrawLinks from 'open-sankey/dist/draw/SankeyDrawLinks';
import * as SankeyDrawLegend from 'open-sankey/dist/draw/SankeyDrawLegend';
import * as SankeyDrawNodesLabel from 'open-sankey/dist/draw/SankeyDrawNodesLabel';
import * as SankeyMenuConfiguration from 'open-sankey/dist/configmenus/SankeyMenuConfiguration';
import * as SankeyMenuTop from 'open-sankey/dist/topmenus/SankeyMenuTop';
import * as SankeyMenuConfigurationLayout from 'open-sankey/dist/configmenus/SankeyMenuConfigurationLayout';
import * as SankeyMenuConfigurationNodesAttributes from 'open-sankey/dist/configmenus/SankeyMenuConfigurationNodesAttributes';
import * as sankeyDragNodeFunc from 'open-sankey/dist/draw/SankeyDragNodes';
import * as OpenSankeyMenuConfigurationNodesTooltip from 'open-sankey/dist/configmenus/SankeyMenuConfigurationNodesTooltip';
import * as SankeyMenuConfigurationNodes from 'open-sankey/dist/configmenus/SankeyMenuConfigurationNodes';
import * as SankeyMenuDialogs from 'open-sankey/dist/dialogs/SankeyMenuDialogs';
import * as SankeyMenuConfigurationNodesIO from 'open-sankey/dist/configmenus/SankeyMenuConfigurationNodesIO';
import * as MenuConfigurationNodesTags from 'open-sankey/dist/configmenus/SankeyMenuConfigurationNodesTags';
import * as ConfigurationLinksTags from 'open-sankey/dist/configmenus/SankeyMenuConfigurationLinksTags';
import * as OpenSankeyMenuConfigurationLinks from 'open-sankey/dist/configmenus/SankeyMenuConfigurationLinks';
import * as SankeyMenuConfigurationLinksData from 'open-sankey/dist/configmenus/SankeyMenuConfigurationLinksData';
import * as SankeyMenuConfigurationLinksAppearence from 'open-sankey/dist/configmenus/SankeyMenuConfigurationLinksAppearence';
import * as SankeyMenuConfigurationLinksTooltip from 'open-sankey/dist/configmenus/SankeyMenuConfigurationLinksTooltip';
import * as SankeyMenuBanner from 'open-sankey/dist/configmenus/SankeyMenuBanner';
import * as SankeyMenuPreferences from 'open-sankey/dist/dialogs/SankeyMenuPreferences';
import * as SankeyDraw from 'open-sankey/dist/draw/SankeyDraw';
import * as SankeyLayout from 'open-sankey/dist/draw/SankeyDrawLayout';
import * as SankeyShape from 'open-sankey/dist/draw/SankeyDrawShapes';
import * as OSModule from 'open-sankey/dist/OSModule';
import * as SankeyExamples from 'open-sankey/dist/topmenus/SankeyMenuExamples';
import OSSankeyApp from 'open-sankey/dist/SankeyApp';
import { DragLegendGElement } from 'open-sankey/dist/draw/SankeyDrawLegend';
/**************************************************************************************************/
export const ArrangeTrade = SankeyLayout.ArrangeTrade;
export const SplitTrade = SankeyLayout.SplitTrade;
export const OpenSankeyConfigurationsMenus = SankeyMenuConfiguration.OpenSankeyConfigurationsMenus;
// export const DrawNodes =
//   SankeyDrawNodes.DrawNodes as DrawAllNodesFType
// export const OpenSankeyDrawNodesLabel =
//   SankeyDrawNodesLabel.OpenSankeyDrawNodesLabel as OpenSankeyDrawNodesLabelFType
export const NodeTooltipsContent = SankeyTooltip.NodeTooltipsContent;
// export const DrawLinks =
//   SankeyDrawLinks.DrawLinks as DrawLinksFType
export const DrawLegend = SankeyDrawLegend.DrawLegend;
export const LinkTooltipsContent = SankeyTooltip.LinkTooltipsContent;
// export const DragLegendGElement =
//   SankeyDrawLegend.DragLegendGElement as DrawLegendFType
export const ReturnValueLink = sankeyUtils
    .ReturnValueLink;
export const ReturnValueNode = sankeyUtils
    .ReturnValueNode;
export const IsAllLinkAttrSameValue = sankeyUtils
    .IsAllLinkAttrSameValue;
export const IsLinkDiplayingValueLocal = sankeyUtils
    .IsLinkDiplayingValueLocal;
export const NodeColor = sankeyUtils.NodeColor;
export const LinkStrokeOSTyped = SankeyDrawFunc.LinkStroke;
export const DrawArrows = SankeyDrawFunc.DrawArrows;
export const DrawGrid = SankeyDrawFunc.DrawGrid;
export const GetSankeyMinWidthAndHeight = SankeyDrawFunc.GetSankeyMinWidthAndHeight;
export const NodeVisibleOnsSvg = SankeyDrawFunc.NodeVisibleOnsSvg;
export const LinkVisibleOnSvg = SankeyDrawFunc.LinkVisibleOnSvg;
export const DeselectVisualyNodes = SankeyDrawFunc.DeselectVisualyNodes;
export const RemoveAnimate = SankeyDrawFunc.RemoveAnimate;
export const SvgDragMiddleMouseStart = SankeyDrawEventFunc.SvgDragMiddleMouseStart;
export const SvgDragMiddleMouseMove = SankeyDrawEventFunc.SvgDragMiddleMouseMove;
export const SimpleGNodeClick = SankeyDrawEventFunc.SimpleGNodeClick;
export const IsNodeDisplayingValueLocal = sankeyUtils.IsNodeDisplayingValueLocal;
export const IsAllNodeAttrSameValue = sankeyUtils.IsAllNodeAttrSameValue;
export const AssignNodeValueToCorrectVar = sankeyUtils.AssignNodeValueToCorrectVar;
export const LinkColor = sankeyUtils
    .LinkColor;
export const AssignLinkValueToCorrectVar = sankeyUtils.AssignLinkValueToCorrectVar;
export const DefaultLinkStyle = sankeyUtils.DefaultLinkStyle;
export const DefaultNodeStyle = sankeyUtils.DefaultNodeStyle;
export const FindMaxLinkValue = sankeyUtils.FindMaxLinkValue;
export const DefaultLink = sankeyUtils.DefaultLink;
export const DefaultNode = sankeyUtils.DefaultNode;
export const updateLayoutOSTyped = SankeyLayout.updateLayout;
export const AdjustSankeyZone = sankeyUtils.AdjustSankeyZone;
// export const synchronizeNodesandLinksIdOSTypedsynchronizeNodesandLinksIdFuncType =
//   SankeyLayout.synchronizeNodesandLinksId as synchronizeNodesandLinksIdFuncType
export const complete_sankey_data = sankeyConvert.complete_sankey_data;
export const convert_data = sankeyConvert.convert_data;
export const convert_nodes = sankeyConvert.convert_nodes;
export const convert_links = sankeyConvert.convert_links;
export const convert_tags = sankeyConvert.convert_tags;
export const NodeDisplayed = sankeyUtils.NodeDisplayed;
export const reorganize_node_outputLinksIdOSTyped = SankeyLayout.reorganize_node_outputLinksId;
export const reorganize_node_inputLinksIdOSTyped = SankeyLayout.reorganize_node_inputLinksId;
export const DefaultSankeyData = sankeyUtils.DefaultSankeyData;
export const GetLinkValue = sankeyUtils.GetLinkValue;
export const LinkTextOSTyped = sankeyUtils.LinkText;
export const LinkVisible = sankeyUtils.LinkVisible;
export const ClickSaveExcel = sankeyLoad.ClickSaveExcel;
export const UploadExemple = sankeyLoad.UploadExemple;
export const RetrieveExcelResults = sankeyLoad.RetrieveExcelResults;
export const ZoomFunction = SankeyDrawEventFunc.ZoomFunction;
export const RepositionneSidebar = SankeyDrawFunc.RepositionneSidebar;
export const EventOnZoneMouseDown = SankeyDrawEventFunc.EventOnZoneMouseDown;
export const EventOnZoneMouseMove = SankeyDrawEventFunc.EventOnZoneMouseMove;
export const EventOnZoneMouseUp = SankeyDrawEventFunc.EventOnZoneMouseUp;
export const OpenSankeyMenus = SankeyMenuTop.OpenSankeyMenus;
export const MenuDraggable = SankeyMenuTop.MenuDraggable;
export const OpenSankeySaveButton = SankeyMenuTop.OpenSankeySaveButton;
// export const LastCheckpointTime =
//   SankeyMenuTop.LastCheckpointTime as LastCheckpointTimeFType
export const OpenSankeyMenuConfigurationLayout = SankeyMenuConfigurationLayout
    .OpenSankeyMenuConfigurationLayout;
export const OpenSankeyConfigurationNodesAttributes = SankeyMenuConfigurationNodesAttributes
    .OpenSankeyConfigurationNodesAttributes;
export const OpenSankeyMenuConfigurationNodes = SankeyMenuConfigurationNodes
    .OpenSankeyMenuConfigurationNodes;
export const OpenSankeyDiagramSelector = SankeyMenuDialogs
    .OpenSankeyDiagramSelector;
export const OpenSankeyMenuConfigurationNodesIO = SankeyMenuConfigurationNodesIO.SankeyMenuConfigurationNodesIO;
export const SankeyMenuConfigurationLinks = OpenSankeyMenuConfigurationLinks.MenuConfigurationLinks;
export const MenuConfigurationLinksData = SankeyMenuConfigurationLinksData
    .MenuConfigurationLinksData;
export const MenuConfigurationLinksAppearence = SankeyMenuConfigurationLinksAppearence.MenuConfigurationLinksAppearence;
export const OpenSankeyToolbarBuilder = SankeyMenuBanner
    .ToolbarBuilder;
export const setDiagram = SankeyMenuBanner
    .setDiagram;
export const OpenSankeyDefaultModalePreferenceContent = SankeyMenuPreferences.OpenSankeyDefaultModalePreferenceContent;
export const preferenceCheck = SankeyMenuPreferences.preferenceCheck;
export const keyHandler = SankeyDraw.keyHandler;
export const ApplySaveJSONDialog = SankeyMenuDialogs.ApplySaveJSONDialog;
export const SankeyMenuConfigurationNodesTooltip = OpenSankeyMenuConfigurationNodesTooltip.SankeyMenuConfigurationNodesTooltip;
export const MenuConfigurationLinksTooltip = SankeyMenuConfigurationLinksTooltip.MenuConfigurationLinksTooltip;
export const SankeyMenuConfigurationNodesTags = MenuConfigurationNodesTags.SankeyMenuConfigurationNodesTags;
export const MenuConfigurationLinksTags = ConfigurationLinksTags.MenuConfigurationLinksTags;
// export const SankeyMenuFileExport:SankeyMenuFileExportFType=SankeyMenuTop.SankeyMenuFileExport
export const DrawAllNodes = SankeyDrawNodes.DrawAllNodes;
export const drawAddNodes = SankeyDrawNodes.drawAddNodes;
export const updateDrawNodeShape = SankeyDrawNodes.updateDrawNodeShape;
export const DrawAllLinks = SankeyDrawLinks.DrawAllLinks;
export const drawAddLinks = SankeyDrawLinks.drawAddLinks;
export const drawLinkShape = SankeyDrawLinks.drawLinkShape;
export const AddDrawLinksEvent = SankeyDrawLinks.AddDrawLinksEvent;
export const RedrawNodesLabel = SankeyDrawNodesLabel.RedrawNodesLabel;
export const DeleteGNodes = SankeyDrawNodes.DeleteGNodes;
export const applyZoomEvent = SankeyDrawEventFunc.applyZoomEvent;
export const resizeDrawingArea = SankeyDrawFunc.resizeDrawingArea;
export const DrawLinkStartSabot = SankeyShape.DrawLinkStartSabot;
export const Modale_resolution_png = SankeyMenuTop.Modale_resolution_png;
export const DrawAll = OSModule.DrawAll;
export const closeAllMenu = OSModule.closeAllMenu;
export const initializeApplicationContext = OSModule.initializeApplicationContext;
export const initializeApplicationData = OSModule.initializeApplicationData;
export const initializeApplicationDraw = OSModule.initializeApplicationDraw;
export const initializeComponentUpdater = OSModule.initializeComponentUpdater;
export const initializeContextMenu = OSModule.initializeContextMenu;
export const initializeElementSelected = OSModule.initializeElementSelected;
export const initializeLinkFunctions = OSModule.initializeLinkFunctions;
export const initializeNodeFunctions = OSModule.initializeNodeFunctions;
export const initializeProcessFunctions = OSModule.initializeProcessFunctions;
export const initializeReinitialization = OSModule.initializeReinitialization;
export const initializeShowDialog = OSModule.initializeShowDialog;
export const initializeUIElementsRef = OSModule.initializeUIElementsRef;
export const initializeAdditionalMenus = OSModule.initializeAdditionalMenus;
export const moduleDialogs = OSModule.moduleDialogs;
export const SankeyApp = OSSankeyApp;
export const InstallEventsOnSVG = OSModule.InstallEventsOnSVG;
export const hideLinkOnDragElement = SankeyDrawFunc.hideLinkOnDragElement;
export const OSTooltip = sankeyUtils.OSTooltip;
export const DragLegendGElementOSTyped = DragLegendGElement;
export const OpposingDragElements = sankeyDragNodeFunc.OpposingDragElements;
export const ReturnOutOfBoundElement = sankeyDragNodeFunc.ReturnOutOfBoundElement;
export const drag_node_text = sankeyDragNodeFunc.drag_node_text;
export const synchronizeNodesandLinksIdOSTyped = SankeyLayout.synchronizeNodesandLinksId;
export const actualizeDrawAreaFrame = SankeyDrawEventFunc.actualizeDrawAreaFrame;
export const DragElements = sankeyDragNodeFunc.DragElements;
export const selectOpenSankeyElementsInSelectionZone = SankeyDrawEventFunc.selectOpenSankeyElementsInSelectionZone;
export const nodeHeight = SankeyLayout.nodeHeight;
export const compute_default_input_outputLinksId = SankeyLayout.compute_default_input_outputLinksId;
export const compute_recycling_horizontal_index = SankeyLayout.compute_recycling_horizontal_index;
export const reorganize_all_input_outputLinksId = SankeyLayout.reorganize_all_input_outputLinksId;
export const RecomputeTrade = SankeyLayout.RecomputeTrade;
export const ComputeAutoSankey = SankeyLayout.ComputeAutoSankey;
export const ComputeParametrization = SankeyLayout.ComputeParametrization;
export const Counter = sankeyLoad.Counter;
export const format_link_value = sankeyUtils.format_link_value;
export const ExempleItem = SankeyExamples.ExempleItem;
