import { ComputeAutoSankey, ComputeParametrization, DefaultLinkStyle, DefaultNodeStyle, FindMaxLinkValue } from '../import/OpenSankey';
import { AssignNodeLocalAttribute } from '../FunctionOSTyped';
// OpenSankey js-code
import { GetRandomInt, } from 'open-sankey/dist/configmenus/SankeyUtils';
// Sankey Icons js-code
import ListIcons from 'sankeyanimation/dist/src/icons/lib_of_icons.json';
//import { ComputeParametrization } from 'open-sankey/dist/draw/SankeyDrawLayout'
// import { DefaultNodeStyle } from 'open-sankey/dist/configmenus/SankeyUtils'
// import { FindMaxLinkValue } from 'open-sankey/dist/configmenus/SankeyUtils'
export const SankeyUnitaryNodeInputStyle = () => {
    const node_style = JSON.parse(JSON.stringify(DefaultNodeStyle()));
    node_style.idNode = 'SankeyUnitaryNodeInputStyle';
    node_style.name = 'Input Nodes for Unitary Sankey ';
    node_style.label_horiz = 'left';
    node_style.label_background = false;
    node_style.font_size = 40;
    node_style.shape_visible = false;
    node_style.bold = false;
    node_style.uppercase = false;
    node_style.node_width = 1;
    node_style.label_vert = 'middle';
    node_style.label_box_width = 300;
    node_style.position = 'parametric';
    node_style.show_value = false;
    // node_style.label_vert_valeur = 'middle'
    // node_style.label_horiz_valeur = 'left'
    // node_style.label_horiz_valeur_shift = -10
    // node_style.label_horiz_shift = 10
    // node_style.value_font_size = 40
    return node_style;
};
export const SankeyUnitaryNodeOutputStyle = () => {
    const node_style = JSON.parse(JSON.stringify(DefaultNodeStyle()));
    node_style.idNode = 'SankeyUnitaryNodeOutputStyle';
    node_style.name = 'Output Nodes for Unitary Sankey';
    node_style.label_horiz = 'right';
    node_style.label_background = false;
    node_style.font_size = 40;
    node_style.shape_visible = false;
    node_style.bold = false;
    node_style.uppercase = false;
    node_style.node_width = 1;
    node_style.label_vert = 'middle';
    node_style.label_box_width = 300;
    node_style.position = 'parametric';
    node_style.show_value = false;
    // node_style.label_vert_valeur = 'middle'
    // node_style.label_horiz_valeur = 'right'
    // node_style.label_horiz_valeur_shift = 10
    // node_style.label_horiz_shift = -10
    // node_style.value_font_size = 40
    return node_style;
};
export const SankeyUnitaryNodeStyle = () => {
    const node_style = JSON.parse(JSON.stringify(DefaultNodeStyle()));
    node_style.idNode = 'SankeyUnitaryNodeStyle';
    node_style.name = 'Center Node for Sankey Unitary';
    node_style.font_size = 40;
    node_style.bold = true;
    node_style.uppercase = true;
    node_style.label_vert = 'bottom';
    node_style.label_horiz = 'middle';
    node_style.node_width = 200;
    node_style.label_box_width = 300;
    //node_style.shape_visible = false
    node_style.position = 'parametric';
    return node_style;
};
export const SankeyUnitaryOutputLinkStyle = () => {
    const link_style = JSON.parse(JSON.stringify(DefaultLinkStyle()));
    link_style.idLink = 'SankeyUnitaryOutputLinkStyle';
    link_style.name = 'Links for Sankey Unitary';
    link_style.label_unit_visible = false;
    link_style.label_unit = '';
    link_style.label_visible = true;
    link_style.label_font_size = 40;
    link_style.label_position = 'end';
    //link_style.text_color = 'white'
    link_style.label_pos_auto = true;
    link_style.unit_link_value_display = 'percent';
    return link_style;
};
export const SankeyUnitaryInputLinkStyle = () => {
    const link_style = JSON.parse(JSON.stringify(DefaultLinkStyle()));
    link_style.idLink = 'SankeyUnitaryInputLinkStyle';
    link_style.name = 'Links for Sankey Unitary';
    link_style.label_unit_visible = false;
    link_style.label_unit = '';
    link_style.label_visible = true;
    link_style.label_font_size = 40;
    //link_style.label_position = 'end'
    //link_style.text_color = 'white'
    link_style.label_pos_auto = true;
    link_style.unit_link_value_display = 'percent';
    return link_style;
};
export const CreateViewNodeUnitary = (t, applicationData, unitary_node, from_zdd, view_name = '') => {
    const { data } = applicationData;
    // Extract and modify Unitary Sankey
    const new_unitary_sankey = JSON.parse(JSON.stringify(data));
    new_unitary_sankey.style_node['SankeyUnitaryNodeInputStyle'] = SankeyUnitaryNodeInputStyle();
    new_unitary_sankey.style_node['SankeyUnitaryNodeOutputStyle'] = SankeyUnitaryNodeOutputStyle();
    new_unitary_sankey.style_node['SankeyUnitaryNodeStyle'] = SankeyUnitaryNodeStyle();
    new_unitary_sankey.style_link['SankeyUnitaryInputLinkStyle'] = SankeyUnitaryInputLinkStyle();
    new_unitary_sankey.style_link['SankeyUnitaryInputLinkStyle'].label_unit = data.style_link['default'].label_unit;
    new_unitary_sankey.style_link['SankeyUnitaryInputLinkStyle'].label_unit_factor = data.style_link['default'].label_unit_factor;
    new_unitary_sankey.style_link['SankeyUnitaryInputLinkStyle'].label_unit_visible = data.style_link['default'].label_unit_visible;
    new_unitary_sankey.style_link['SankeyUnitaryOutputLinkStyle'] = SankeyUnitaryOutputLinkStyle();
    new_unitary_sankey.style_link['SankeyUnitaryOutputLinkStyle'].label_unit = data.style_link['default'].label_unit;
    new_unitary_sankey.style_link['SankeyUnitaryOutputLinkStyle'].label_unit_factor = data.style_link['default'].label_unit_factor;
    new_unitary_sankey.style_link['SankeyUnitaryOutputLinkStyle'].label_unit_visible = data.style_link['default'].label_unit_visible;
    new_unitary_sankey.node_label_separator = ' - ';
    new_unitary_sankey.node_label_separator_first = true;
    new_unitary_sankey.show_structure = 'free_value';
    new_unitary_sankey.view = [];
    //new_unitary_sankey.nodeTags = { 'Type de noeud' : data.nodeTags['Type de noeud']}
    //new_unitary_sankey.fluxTags = {}
    new_unitary_sankey.labels = {};
    new_unitary_sankey.style_link['default'].dashed = false;
    new_unitary_sankey.colorMap = 'no_colormap';
    new_unitary_sankey.nodesColorMap = 'no_colormap';
    if (new_unitary_sankey.icon_catalog === undefined) {
        new_unitary_sankey.icon_catalog = {};
    }
    // Extracts Unitary Sankey nodes
    // Get type of the unitary node (it can be 'secteur','produit','échange' or undefined)
    const type_node = (unitary_node.tags['Type de noeud'] !== undefined && unitary_node.tags['Type de noeud'].length > 0) ? (unitary_node.tags['Type de noeud'][0]) : undefined;
    // Add an icon to the unitary node depending of it node type
    delete unitary_node.local;
    if (type_node === 'secteur') {
        AssignNodeLocalAttribute(unitary_node, 'shape_visible', false);
        unitary_node.iconName = 'node_industrie';
        unitary_node.iconColor = 'black';
        unitary_node.iconVisible = true;
        unitary_node.is_image = false;
        unitary_node.image_src = '';
        delete unitary_node.iconViewBox;
        new_unitary_sankey.icon_catalog['node_industrie'] = ListIcons.waste.industry;
    }
    else /*if (type_node === 'produit')*/ {
        AssignNodeLocalAttribute(unitary_node, 'shape', 'ellipse');
        // unitary_node.iconName = 'node_market_process'
        // unitary_node.iconColor = 'black'
        // unitary_node.iconVisible = true
        unitary_node.is_image = false;
        unitary_node.image_src = '';
        //new_unitary_sankey.icon_catalog['node_market_process'] = ListIcons.waste.atom
    }
    new_unitary_sankey.legend_position = [100, 100];
    new_unitary_sankey.legend_police = 30;
    Object.values(new_unitary_sankey.levelTags).forEach(tagg => delete unitary_node.tags[tagg.group_name]);
    //Object.values(new_unitary_sankey.nodeTags).forEach(tagg=>tagg.activated = false)
    const inputNodes = unitary_node.inputLinksId.map(lid => new_unitary_sankey.nodes[new_unitary_sankey.links[lid].idSource]);
    inputNodes.forEach(n => n.inputLinksId = []);
    const outputNodes = unitary_node.outputLinksId.map(lid => new_unitary_sankey.nodes[new_unitary_sankey.links[lid].idTarget]);
    outputNodes.forEach(n => n.outputLinksId = []);
    const nodes = [...[unitary_node], ...inputNodes, ...outputNodes];
    new_unitary_sankey.nodes = {};
    nodes.forEach(n => new_unitary_sankey.nodes[n.idNode] = n);
    //new_unitary_sankey.nodes = Object.assign({}, ...nodes.map(n => ({ [n.idNode]: { ...n } }))) as unknown as { [x: string]: SankeyDevNode; }
    // Extracts Unitary Sankey links
    const inputLinks = unitary_node.inputLinksId.map(lid => new_unitary_sankey.links[lid]);
    const outputLinks = unitary_node.outputLinksId.map(lid => new_unitary_sankey.links[lid]);
    const links = [...inputLinks, ...outputLinks];
    new_unitary_sankey.links = Object.assign({}, ...links.map(n => ({ [n.idLink]: Object.assign({}, n) })));
    const link_keys = Object.values(new_unitary_sankey.links).map(l => l.idLink);
    new_unitary_sankey.linkZIndex = new_unitary_sankey.linkZIndex.filter(lz => link_keys.includes(lz)).map(l => l);
    // Modify  Unitary Sankey nodes
    const appUnitaryData = JSON.parse(JSON.stringify(applicationData));
    appUnitaryData.data = new_unitary_sankey;
    appUnitaryData.data.style_node['default'].dx = 400;
    Object.values(new_unitary_sankey.nodes).forEach(n => {
        if (n.inputLinksId.length == 0 || n.outputLinksId.length == 0) {
            const local_agregation = n.local ? n.local.local_aggregation : undefined;
            delete n.local;
            if (local_agregation !== undefined) {
                n.local = { local_aggregation: local_agregation };
            }
        }
        delete n.x_label;
        delete n.y_label;
        if (n.inputLinksId.length == 0) {
            n.style = 'SankeyUnitaryNodeInputStyle';
        }
        else if (n.outputLinksId.length == 0) {
            n.style = 'SankeyUnitaryNodeOutputStyle';
        }
        else {
            n.style = 'SankeyUnitaryNodeStyle';
        }
        n.colorTag = 'no_colormap';
        n.colorParameter = 'local';
        delete n.tags['Type de noeud'];
    });
    ComputeAutoSankey(appUnitaryData, true);
    if (new_unitary_sankey.icon_catalog === undefined) {
        new_unitary_sankey.icon_catalog = {};
    }
    Object.values(new_unitary_sankey.nodes).forEach(n => {
        n.x -= 50;
        n.y += 200;
    });
    ComputeParametrization(appUnitaryData);
    Object.values(new_unitary_sankey.links).forEach(l => {
        if (l.idSource == unitary_node.idNode) {
            l.style = 'SankeyUnitaryOutputLinkStyle';
        }
        else {
            l.style = 'SankeyUnitaryInputLinkStyle';
        }
        delete l.local;
        l.colorTag = 'no_colormap';
        return l;
    });
    let max_link_value = 0;
    Object.values(new_unitary_sankey.links).forEach(link => {
        // We use a function to max value for each link because
        // each link can have multiple values
        max_link_value = FindMaxLinkValue(new_unitary_sankey, max_link_value, link.value, link);
    });
    // Create string containing datatag info for zdt
    let DT_info = '';
    // Add additional info concerning the dataTag at the moment of the creation of the unitary sankey
    // because once the unitary sankey is created, we delete the datatags
    // const data_tags = Object.assign({}, new_unitary_sankey.dataTags)
    // Object.entries(data_tags).forEach(tag_group => {
    //   const intro_group_data_tags: string = ' : ' + Object.values(tag_group[1].tags).filter(t => t.selected).map(t => t.name).join(', ')
    //   DT_info += '<p style="font-size:40px">' + tag_group[1].group_name + intro_group_data_tags + '</p>'
    // })
    // new_unitary_sankey.dataTags = {}
    //max_link_value += 1 // Protection if all values are at 0
    // Get scale from max value
    new_unitary_sankey.user_scale = data.maximum_flux ? Math.min(data.maximum_flux, max_link_value) : max_link_value;
    new_unitary_sankey.user_scale /= 2;
    new_unitary_sankey.display_style.filter_label = 0;
    // attributes are standardized
    // Also delete node that doesn't have link attached to it
    // const to_delete = Object.entries(new_unitary_sankey.nodes).filter(n => n[1].outputLinksId.length === 0 && n[1].inputLinksId.length === 0).map(n => n[0])
    // to_delete.forEach(nid => delete new_unitary_sankey.nodes[nid])
    // // Get a formated version unitary_node,
    // // in this variable output/input links id of the unitary_node are filtered according to the new unitary sankey
    // // Notably usefull for compute_auto_unitary_sankey that run throught the variables input/output links id of the contextualised node,
    // // so it could have accessed link id in inputLinksId of unitary_node that are no longer present in new_unitary_sankey
    // const formated_unitary_node = new_unitary_sankey.nodes[unitary_node.idNode]
    // ======Add ZDT======
    // Get dimensions for labels
    // Get the node the most at left
    const n_label = CreateZDTForUnitarySankey(t, applicationData, new_unitary_sankey, unitary_node, type_node);
    // Perform some configuration if the unitary go to a new view or an existing view
    CreateOrInsertUnitarySankeyView(t, applicationData, view_name, new_unitary_sankey, n_label, type_node, unitary_node, 50, 50);
};
export const CreateOrInsertUnitarySankeyView = (t, applicationData, view_name, new_unitary_sankey, n_label, type_node, unitary_node, min_x, min_y) => {
    const { master_data, set_master_data, data, set_data, set_view } = applicationData;
    if (!master_data) {
        return;
    }
    if (n_label) {
        new_unitary_sankey.labels[n_label.idLabel] = n_label;
    }
    let root_name = 'Noeud';
    if (type_node !== undefined) {
        root_name = type_node == 'produit' ? 'Produit' : 'Secteur';
    }
    const new_id = 'view_' + String(new Date().getTime()) + String(GetRandomInt(100));
    master_data.view.push({
        id: new_id,
        view_data: new_unitary_sankey,
        nom: unitary_node.name,
        details: '',
        heredited_attr_from_master: []
    });
};
const CreateZDTForUnitarySankey = (t, applicationData, new_unitary_sankey, unitary_node, type_node) => {
    const { master_data } = applicationData;
    if (!master_data) {
        return;
    }
    // Info from data source in ZDT
    // customize the info to add in the zdt
    let content_zdt = '';
    let type_process = '';
    if (type_node === 'produit' || type_node === 'secteur') {
        if (type_node === 'produit') {
            type_process = 'Process de marché';
        }
        else {
            type_process = 'Process de transformation';
        }
    }
    else {
        type_process = t('view.template_unitary_zdt_content_of_node');
    }
    // Add the info to the zdt
    const name_view = (master_data.view && master_data.current_view && master_data.current_view !== 'none') ?
        master_data.view.filter(v => v.id === master_data.current_view)[0].nom :
        t('Menu.home');
    content_zdt += '<p class="ql-align-center" style="font-size:40px">' + type_process + ' : <strong>' + unitary_node.name + '</strong></p>';
    //content_zdt += '<p style="font-size:40px">' + t('view.template_unitary_zdt_content') + ' <strong>' + name_view + ' </strong></p>'
    //content_zdt += DT_info
    // Create the zdt
    const n_label = {
        idLabel: 'label_' + String(new Date().getTime()),
        title: 'Description',
        content: content_zdt,
        label_width: 1600, //((max_x - min_x)),
        label_height: 700, //((max_y - min_y) + 150),
        color: 'white',
        color_border: 'black',
        opacity: 100,
        transparent_border: false,
        x: 50, //min_x,
        y: 50, //min_y - 100,
        is_image: false,
        image_src: ''
    };
    return n_label;
};
